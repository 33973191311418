import { API } from 'aws-amplify';

/**
 * Custom hook that groups a collection of zip-eligibility based API endpoints
 * @returns { Object } - { fetchZipList, findZip } - The former one will return a list of available
 * states per parter id and the latter will return state information given a zip code value
 */
const useZipEligibilityAPI = () => {
  const fetchZipList = async (partnerId) => {
    try {
      return await API.get('oshiAPI', `/service/states?partner=${partnerId}`);
    } catch (error) {
      throw new Error('Fetching zip list failed');
    }
  };

  const findZip = async (zipCode) => {
    try {
      const stateFromZip = await API.get('oshiAPI', `/service/zip/${zipCode}`);
      if (stateFromZip.name !== 'USPS_ERROR') {
        return stateFromZip;
      }
      return 'invalid';
    } catch (error) {
      throw new Error('Finding zip failed');
    }
  };

  return {
    fetchZipList,
    findZip,
  };
};

export default useZipEligibilityAPI;
