import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Welcome from '../containers/Welcome';
import PrivacyPolicy from '../containers/PrivacyPolicy';
import Name from '../containers/Name';
import Email from '../containers/Email';
import Password from '../containers/Password';
import AccountCreated from '../containers/AccountCreated';
import BookAnAppointment from '../containers/BookAnAppointment';
import Sex from '../containers/Sex';
import Birthdate from '../containers/Birthdate';
import Address from '../containers/Address';
import StreetAddress from '../containers/StreetAddress';
import Skip from '../containers/Skip';
import EligibleStateChecks from '../containers/EligibleStateCheck';
import OffBoarding from '../containers/OffBoarding';
import AccountCreationError from '../containers/AccountCreationError';
import CancelationPolicy from '../containers/CancelationPolicy';

import AppointmentBook from '../containers/AppointmentBook';
import AppointmentOverview from '../containers/AppointmentOverview';
import AppointmentConfirm from '../containers/AppointmentConfirm';
import MembershipTransition from '../containers/MembershipTransition';
import MembershipCashPay from '../containers/MembershipCashPay';
import NetworkError from '../containers/NetworkError';
import DeeplinkHandle from '../containers/DeeplinkHandle';
import FourOhFour from '../containers/FourOhFour';
import ProtectedRoute from './ProtectedRoute';
import PimIdRoute from './PimIdRoute';
import useBrowserTabActiveness from '../hooks/useBrowserTabActiveness';
import { userTabActivenessEvent } from '../actions/segment';

export const signUpRoutes = {
  '/name': true,
  '/name/optum': true,
  '/email': true,
  '/password': true,
  '/privacy-policy': true,
  '/gender': true,
  '/birthdate': true,
  '/street-address': true,
  '/address': true,
};

const Routes = ({ userTabActivenessEvent }) => {
  useBrowserTabActiveness(userTabActivenessEvent);

  return (
    <Switch>
      <Route path='/cancelation-policy'>
        <CancelationPolicy />
      </Route>
      <Route exact path='/name/:partnerName'>
        <Name />
      </Route>
      <Route exact path='/name'>
        <Name />
      </Route>
      <ProtectedRoute path='/pimid' childCmp={PimIdRoute} />
      <Route path='/email'>
        <Email />
      </Route>
      <Route path='/state'>
        <EligibleStateChecks />
      </Route>
      <Route path='/password'>
        <Password />
      </Route>
      <Route path='/privacy-policy'>
        <PrivacyPolicy />
      </Route>
      <Route path='/account-created'>
        <AccountCreated />
      </Route>
      <Route path='/gender'>
        <Sex />
      </Route>
      <Route path='/birthdate'>
        <Birthdate />
      </Route>
      <Route path='/street-address'>
        <StreetAddress />
      </Route>
      <Route path='/address'>
        <Address />
      </Route>
      <Route path='/get-symptom-relief'>
        <BookAnAppointment />
      </Route>
      <Route path='/first-appointment-book'>
        <AppointmentBook />
      </Route>
      <Route path='/first-appointment-overview'>
        <AppointmentOverview />
      </Route>
      <Route path='/first-appointment-confirm'>
        <AppointmentConfirm />
      </Route>
      <Route path='/membership-transition'>
        <MembershipTransition />
      </Route>
      <Route path='/membership-cash-pay'>
        <MembershipCashPay />
      </Route>
      <Route path='/skip'>
        <Skip />
      </Route>
      <Route path='/offboarding'>
        <OffBoarding />
      </Route>
      <Route path='/network-error'>
        <NetworkError />
      </Route>
      <Route path='/account-creation-error'>
        <AccountCreationError />
      </Route>
      <Route path='/go-to-app'>
        <DeeplinkHandle />
      </Route>
      <Route exact path='/'>
        <Welcome />
      </Route>
      <Route component={FourOhFour} />
    </Switch>
  );
};

export default connect(null, { userTabActivenessEvent })(Routes);
