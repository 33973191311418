import { useFormik } from 'formik';

import { localStorageGet } from '../utils/localStorageHelper';
import useYupSchemaBuilder from './useYupSchemaBuilder';

/**
 * Custom hook to handle initial configuration and UI events of /state page UI form.
 * Builts form validation by using methods from yup schema builder hook and handles form submission UI events.
 *
 * @param { Object } uiEvents - Set of UI events handlers for UI form submission
 * @returns { Object } - { formik, handleSubmit, handleOnKeyPress} `formik` property returns formik
 * configuration to be used in the UI logic of the UI page component. `handleSubmit` and `handleOnKeyPress`
 * are functions to handle UI form submission but with slight difference, the latter will submit the form
 * by hitting `enter` key, the former with a single `click`.
 */
const useEligibleStateUIForm = ({ handleSubmitFn, handleOnKeyPressFn }) => {
  const { getYupShape, zipCodeValidator } = useYupSchemaBuilder();
  const { zipcode } = localStorageGet('zipcode');
  const zipValidationSchema = getYupShape({
    zipcode: zipCodeValidator(),
  });
  const handleSubmit = (values) => {
    handleSubmitFn(values);
  };

  const formik = useFormik({
    initialStatus: { existingValues: zipcode },
    initialValues: {
      zipcode: zipcode || '',
    },
    validationSchema: zipValidationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleOnKeyPress = (evt) => {
    handleOnKeyPressFn(evt, formik);
  };

  return { formik, handleSubmit, handleOnKeyPress };
};

export default useEligibleStateUIForm;
