import React from 'react';
import { useLocation } from 'react-router-dom';
import { ONBOARDING_APPT_TYPE } from '../constants/appointmentTypes';

const useSegmentTracker = (
  appointmentType = ONBOARDING_APPT_TYPE,
  fn
) => {
  const location = useLocation();

  const trackSegmentFn = React.useCallback(
    (...args) => {
      fn(
        location.pathname,
        appointmentType,
        ...args
      );
    },
    [appointmentType, location, fn]
  );

  return {
    trackSegmentFn,
  };
};

export default useSegmentTracker;
