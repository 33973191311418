import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import useZipEligibilityAPI from './useZipEligibilityAPI';
import useIsAsyncMounted from './useIsAsyncMounted';
import { localStorageGet } from '../utils/localStorageHelper';

/**
 * Custom hook that handles UI logic flow for zip eligibility feature used in some app pages
 *
 * @returns { Object } - Returns object with two properties: availableStates and zipLookUp.
 * The first one is the value of availableStates list and the second one is a function which
 * returns a found zip code value from a API call.
 */
const useZipEligibilityUILogic = (setIsFetching) => {
  const [availableStates, setAvailableStates] = useState([]);
  const history = useHistory();
  const { fetchZipList, findZip } = useZipEligibilityAPI();
  const partner = localStorageGet('partner');

  const zipLookUp = async (zipcode) => {
    try {
      return await findZip(zipcode);
    } catch (error) {
      history.push('network-error', { redirectLink: window.location.href });
    }
  };

  const fetchZipcodes = async () => {
    try {
      setIsFetching(true);
      const fetchedList = await fetchZipList(partner);
      setIsFetching(false);
      return fetchedList;
    } catch (error) {
      setIsFetching(false);
      history.push('network-error', { redirectLink: window.location.href });
      return null;
    }
  };

  useIsAsyncMounted(fetchZipcodes, setAvailableStates);

  return { availableStates, zipLookUp };
};

export default useZipEligibilityUILogic;
