/* eslint-disable no-use-before-define */
import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import '../assets/styles/calendar.css';
import { AppointmentContext } from '../utils/context';
import ScrollComponent from '../components/ScrollComponent';
import OshiHeader from '../components/OshiHeader';
import OshiBanner from '../components/OshiBanner';
import OshiNextButton from '../components/OshiNextButton';
import OshiProviderInfoContainer from '../components/OshiProviderInfoContainer';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import usePatientProfile from '../hooks/usePatientProfile';
import {
  AppointmentCalendarInteraction,
  AppointmentBookTimeScroll,
  AppointmentBookTimeSelect,
  AppointmentBookBooked,
  AppointmentBookGoBack,
  createTrackEvent,
  identifyPatientSegment,
  userSubmitEvent,
  trackOnLoadedScreenEvent,
} from '../actions/segment';
import { segmentTypes } from '../constants';

import { setIsFetching } from '../actions';
import next from '../img/icon_next.png';
import previous from '../img/icon_prev.png';
import getUrlParameter from '../utils/getUrlParameter';
import useComponentFocus from '../hooks/useComponentFocus';

import {
  REFERRAL_APPT_TYPE,
  ONBOARDING_APPT_TYPE,
  FOLLOW_UP_APPT_TYPE,
} from '../constants/appointmentTypes';
import { palette } from '../theme/palette';
import useSegmentTracker from '../hooks/useSegmentTracker';

const formatDate = (date) => {
  const days = [
    'Sunday,',
    'Monday,',
    'Tuesday,',
    'Wednesday,',
    'Thursday,',
    'Friday,',
    'Saturday,',
  ];
  const dateObject = new Date(date);
  const day = dateObject.getDay();
  const dayArr = String(date).split(' ').splice(0, 4);
  dayArr.splice(0, 1, days[day]);
  const joinedDate = dayArr.join(' ');
  return joinedDate;
};

//todo: remove once all active users are on updated version of app
function getAppointmentTypeBC(
  appointmentTypeFromWebview = null,
  providerIdFromWebview
) {
  if (appointmentTypeFromWebview) {
    // means you are in new version
    return appointmentTypeFromWebview;
  } else {
    // means you are in old version
    // old version doesn't handle referrals
    if (providerIdFromWebview) {
      return FOLLOW_UP_APPT_TYPE;
    } else {
      return ONBOARDING_APPT_TYPE;
    }
  }
}

const AppointmentCalendar = ({
  AppointmentCalendarInteraction,
  AppointmentBookTimeScroll,
  AppointmentBookTimeSelect,
  AppointmentBookBooked,
  AppointmentBookGoBack,
  setIsFetching,
  createTrackEvent,
  identifyPatientSegment,
  userSubmitEvent,
  trackOnLoadedScreenEvent,
  showBanner,
}) => {
  const [headlineRef, setHeadlineFocus] = useComponentFocus();
  const [bookApptRef, setBookApptBtnFocus] = useComponentFocus();
  const [timeslotsRef, setSingleTimeslotBtnFocus] = useComponentFocus([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selected, setSelected] = useState(new Date());
  const [selectedDuration, setSelectedDuration] = useState();
  const [appointmentFullDate, setAppointmentFullDate] = useState(new Date());
  const [appointmentDate, setAppointmentDate] = useState(
    formatDate(new Date())
  );
  const [appointmentTime, setAppointmentTime] = useState(false);
  const [appointmentTimeNode, setAppointmentTimeNode] = useState('');
  const [appointment, setAppointment] = useContext(AppointmentContext);
  const [
    sortedAppointmentForSelectedDate,
    setSortedAppointmentForSelectedDate,
  ] = useState([]);
  const [ButtonDisableState, setButtonDisableState] = useState(true);
  const [availableAppointments, setAvailableAppointments] = useState({});
  const history = useHistory();
  const platform = getUrlParameter('platform', history);
  const native = platform === 'native';
  const isRescheduleingFromWebView =
    getUrlParameter('reschedule', history) === 'true';
  const providerIdFromWebview = getUrlParameter('pid', history);
  const congnitoIdFromWebview = getUrlParameter('uid', history);
  const appointmentTypeIdFromWebview = getUrlParameter('appt_type', history);
  const appointmentTypeFromWebview = getAppointmentTypeBC(
    getUrlParameter('type', history),
    providerIdFromWebview
  );
  const { trackSegmentFn: trackOnSubmit } = useSegmentTracker(
    appointmentTypeFromWebview,
    userSubmitEvent
  );
  const { trackSegmentFn: trackOnLoad } = useSegmentTracker(
    appointmentTypeFromWebview,
    trackOnLoadedScreenEvent
  );
  const patient = usePatientProfile(native);
  const [availableDates, setAvailableDates] = useState([]);
  const subscriptionStatusFromWebview = getUrlParameter(
    'subscription_status',
    history
  )
    .toString()
    .toLowerCase();
  const formatAppointmentTimeslotDate = (date) =>
    moment(date).format('M/D/YYYY');
  const sortAppointmentTimeslotsInAsc = (timeslots) =>
    timeslots.sort((a, b) => a.available_from - b.available_from);

  // need to add some margin when in webview
  const btnStyles = {
    color: palette.melon800,
    fontWeight: 600,
  };

  if (native) {
    btnStyles.marginTop = '50px';
  }

  const patientIdentifiedThroughSegment = localStorageGet(
    'patientIdentifiedThroughSegment'
  );
  const salesForceId = getUrlParameter('sfId', history);

  const didComeFromAppointmentOverview =
    getUrlParameter('from_location', history) === 'appointmentOverview';

  useLayoutEffect(() => {
    // Fetch flag is true to display loading screen before rendering screen components
    setIsFetching(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorageSave('reservedAppointment', null);
  }, []);

  useEffect(() => {
    const availableApptsList = Object.values(availableAppointments);
    appointmentTypeFromWebview &&
      localStorageSave('appointmentType', {
        appointmentType: appointmentTypeFromWebview,
      });
    if (availableApptsList[0]?.length > 0) {
      if (appointmentTypeFromWebview === REFERRAL_APPT_TYPE) {
        trackOnLoad(availableApptsList[0][0].provider_role);
      } else {
        trackOnLoad();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypeFromWebview, availableAppointments]);

  useEffect(() => {
    if (
      native && // if native
      salesForceId &&
      !patientIdentifiedThroughSegment && // if we haven't identified this patient
      salesForceId !== patientIdentifiedThroughSegment // if id's don't match with what is tored
    ) {
      identifyPatientSegment(salesForceId, {});
      localStorageSave('patientIdentifiedThroughSegment', salesForceId);
    }
    // eslint-disable-next-line
  }, [native, salesForceId, patientIdentifiedThroughSegment]);

  useLayoutEffect(() => {
    // Fetch appointment availability timeslots as long as we have either patient data or native flag is true (running from mobile app)
    (!!patient || !!native) && fetchAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, native]);

  useEffect(() => {
    headlineRef.current && setHeadlineFocus();
    // eslint-disable-next-line
  }, [setHeadlineFocus]);

  useEffect(() => {
    !isFirstRender &&
      timeslotsRef.current &&
      setSingleTimeslotBtnFocus(timeslotsRef.current[0]);
    // eslint-disable-next-line
  }, [isFirstRender, sortedAppointmentForSelectedDate]);

  useEffect(() => {
    appointmentTime &&
      appointmentTimeNode >= 0 &&
      bookApptRef.current &&
      setBookApptBtnFocus();
    // eslint-disable-next-line
  }, [appointmentTime, appointmentTimeNode, setBookApptBtnFocus]);

  const formatWeekDayNames = (locale) => {
    const str = String(locale).split('');
    const firstChar = str[0];
    return firstChar;
  };

  const fetchAvailability = async () => {
    try {
      const transaction = Sentry.startTransaction({
        name: 'fetch-appointment-transaction',
      });
      const mappedAvailableTimes = {};
      const getApptsUrl =
        (native && appointmentTypeFromWebview === FOLLOW_UP_APPT_TYPE) ||
        isRescheduleingFromWebView
          ? `/providers/${providerIdFromWebview}/availability`
          : '/providers/availability';
      const queryStringParameters = {};

      if (native) {
        queryStringParameters.cognito_id = congnitoIdFromWebview;

        if (appointmentTypeFromWebview === ONBOARDING_APPT_TYPE) {
          queryStringParameters.appointment_type = ONBOARDING_APPT_TYPE;
        } else if (appointmentTypeFromWebview === REFERRAL_APPT_TYPE) {
          queryStringParameters.appointment_type_id = appointmentTypeIdFromWebview;
        } else {
          queryStringParameters.appointment_type_id = appointmentTypeIdFromWebview;
          queryStringParameters.subscriptionStatus = subscriptionStatusFromWebview;
        }
        localStorageSave('subscriptionStatus', {
          subscriptionStatus: subscriptionStatusFromWebview,
        });
      } else {
        queryStringParameters.appointment_type = ONBOARDING_APPT_TYPE;
        queryStringParameters.cognito_id = patient.cognito_id;
      }

      const fetchedAppointments = await API.get('oshiAPI', getApptsUrl, {
        queryStringParameters,
      });

      transaction.finish();

      // loop over available appointments array and map the times
      if (fetchedAppointments.length > 0) {
        fetchedAppointments.forEach((date) => {
          const key = formatAppointmentTimeslotDate(date.available_from);

          if (mappedAvailableTimes[key]) {
            mappedAvailableTimes[key].push(date);
          } else {
            mappedAvailableTimes[key] = [date];
          }
        });
      } else {
        mappedAvailableTimes[formatAppointmentTimeslotDate(new Date())] = [];
      }

      //set the calendar to the earliest date with available appoitments on start
      if (fetchedAppointments.length > 0) {
        const firstAvailableDate = new Date(
          Object.keys(mappedAvailableTimes).sort((a, b) => {
            return Date.parse(a) - Date.parse(b);
          })[0]
        );
        const firstAvailableDateFormatted = formatAppointmentTimeslotDate(
          firstAvailableDate
        );
        setSelected(firstAvailableDate);
        setSelectedDuration(
          mappedAvailableTimes[firstAvailableDateFormatted][0]?.duration
        );
        setSortedAppointmentForSelectedDate(
          sortAppointmentTimeslotsInAsc(
            mappedAvailableTimes[firstAvailableDateFormatted]
          )
        );
        setAppointmentDate(formatDate(firstAvailableDate));
      }

      setAvailableDates(Object.keys(mappedAvailableTimes));
      setAvailableAppointments(mappedAvailableTimes);
    } catch (error) {
      createTrackEvent(segmentTypes.SERVER_ERROR, `Server error`);
      history.push('network-error', { redirectLink: window.location.href });
    } finally {
      setIsFetching(false);
    }
  };

  const clearTimeslotElRefs = () => {
    if (timeslotsRef.current.length > 0) {
      timeslotsRef.current = [];
    }
  };

  const onDateChange = (date) => {
    const formattedDate = formatAppointmentTimeslotDate(date);
    clearTimeslotElRefs();

    const sortedDailyAppointments = sortAppointmentTimeslotsInAsc(
      availableAppointments[formattedDate]
    );
    setSortedAppointmentForSelectedDate(sortedDailyAppointments);

    isFirstRender && setIsFirstRender(false);
    setAppointmentFullDate(date);
    setAppointmentDate(formatDate(date));
    setSelected(date);
    setSelectedDuration(availableAppointments[formattedDate][0]?.duration);
    AppointmentCalendarInteraction();

    setAppointmentTime(null);
    setAppointmentTimeNode(-1);
  };

  const onTimeSelect = (time, node) => {
    setAppointmentTime(time);
    setAppointmentTimeNode(node);
    AppointmentBookTimeSelect();
    if (time.length > 0) {
      if (ButtonDisableState) {
        setButtonDisableState(false);
      }
    } else {
      setButtonDisableState(true);
    }
  };

  const onBook = () => {
    setAppointment({
      Appointment: true,
      AppointmentTime: appointmentTime,
      AppointmentDate: appointmentDate,
      AppointmentTimeNode: appointmentTimeNode,
      AppointmentFullDate: appointmentFullDate,
    });
    if (subscriptionStatusFromWebview !== 'false') {
      AppointmentBookBooked({
        userStatus: subscriptionStatusFromWebview,
      });
    } else {
      AppointmentBookBooked({
        userStatus: patient.subscription_status,
      });
    }

    trackOnSubmit(
      appointmentTypeFromWebview === REFERRAL_APPT_TYPE && appointmentTime
        ? appointmentTime.provider_role
        : null
    );

    if (native) {
      history.push(`/first-appointment-confirm${history.location.search}`);
    } else if (didComeFromAppointmentOverview) {
      history.push(
        `/first-appointment-confirm?from_location=appointmentOverview`
      );
    } else {
      history.push(`/first-appointment-confirm`);
    }
  };

  return (
    <PageContainer>
      <TitleContainer>
        <OshiHeader testId='appointment-book-header' />
      </TitleContainer>

      <Background />

      <OshiBanner />
      <FlexContainer>
        <CopyWrapper
          showBanner={showBanner}
          ref={headlineRef}
          role='status'
          aria-labelledby='copyTitle copySubtitle'
        >
          <CopyTitle id='copyTitle'>
            When would be convenient for you?
          </CopyTitle>
          <CopySubTitle id='copySubtitle'>
            Choose a date to see available times.
          </CopySubTitle>
        </CopyWrapper>
        {Object.keys(availableAppointments).length > 0 &&
          Object.values(availableAppointments)[0].length > 0 && (
            <OshiProviderInfoContainer
              title={
                providerIdFromWebview
                  ? Object.values(availableAppointments)[0][0].provider_name
                  : Object.values(availableAppointments)[0][0].provider_role
              }
              subTitle={
                providerIdFromWebview
                  ? Object.values(availableAppointments)[0][0].provider_role
                  : null
              }
              duration={selectedDuration}
            />
          )}
        <ContentContainer>
          <CalendarContainer testId='appointment-book-calendar-container'>
            <Calendar
              onChange={onDateChange}
              prevLabel={<PreviousIcon src={previous} />}
              nextLabel={<NextIcon src={next} />}
              value={selected}
              minDetail='month'
              nextAriaLabel='Next Month'
              prevAriaLabel='Previous Month'
              next2Label={null}
              prev2Label={null}
              calendarType='US'
              formatShortWeekday={(locale, date) =>
                formatWeekDayNames(date, 'dd')
              }
              maxDetail='month'
              view='month'
              showNeighboringMonth={false}
              tileDisabled={({ date, view }) =>
                !availableDates.includes(moment(date).format('l'))
              }
            />
          </CalendarContainer>

          <Border />

          <TimeWrapper>
            <TimeTitle aria-label='Times Available'>Times Available</TimeTitle>
            <ScrollComponent
              ref={timeslotsRef}
              testId='appointment-book-time-component'
              labels={sortedAppointmentForSelectedDate}
              width='365px'
              initialSelect={appointment.AppointmentTimeNode}
              onSelect={onTimeSelect}
              onScrollSegment={AppointmentBookTimeScroll}
            />
          </TimeWrapper>
        </ContentContainer>
        <BottomContainer>
          <OshiNextButton
            ref={bookApptRef}
            onClick={onBook}
            disabled={!appointmentTime}
            styles={btnStyles}
            buttonTitle='Choose This Time'
          />
        </BottomContainer>
      </FlexContainer>
    </PageContainer>
  );
};

const PageContainer = styled.main`
  width: 100vw;
  height: 100vh;
  display flex;
  flex: 1;
  align-items: center;
  @media (max-width: 768px) {
    height: 100%;
  }
  flex-direction: column;
`;
const Background = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  background-color: #fff;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 90%;
  margin: 0 24px;
  flex: 1;
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 550px;
  align-items: center;
`;
const TitleContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  @media only screen and (min-width: 768px) {
    margin-top: 0;
  }
`;
const CopyWrapper = styled.div`
  padding-top: ${(props) => (props.showBanner ? '30px' : '82px')};
  max-width: 327px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  @media only screen and (min-width: 768px) {
    padding-top: ${(props) => (props.showBanner ? '90px' : '176px')};
    max-width: 672px;
  }
`;
const CopyTitle = styled.div`
  text-align: left;
  font-size: 32px;
  font-family: 'Source Serif Pro', serif;
  line-height: 130%;
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 24px;
  color: ${palette.navy};
  @media only screen and (min-width: 768px) {
    margin-bottom: 8px;
    font-size: 56px;
  }
`;
const CopySubTitle = styled.div`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  font-family: Usual;
  line-height: 168%;
  letter-spacing: 0.06em;
  white-space: pre-line;
  color: ${palette.coolGray};
  width: 100%;
`;
const ContentContainer = styled.div`
  padding-top: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 768px) {
    padding-top: 84px;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;
const CalendarContainer = styled.div`
  display: flex;
  width: 343.41px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: 768px) {
    height: auto;
  }
`;
const Border = styled.div`
  position: relative;
  display: none;
  left: 23px;
  top: 16px;
  height: 228px;
  border: 0.5px solid ${palette.coolGray200};
  @media only screen and (min-width: 768px) {
    display: block;
  }
`;
const TimeWrapper = styled.div`
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  padding-top: 23px;
  @media only screen and (min-width: 768px) {
    width: 228px;
    height: 316px;
    justify-content: flex-start;
    border: none;
  }
`;
const TimeTitle = styled.div`
  max-width: 576px;
  text-align: center;
  font-size: 12px;
  font-family: Usual;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.06em;
  white-space: pre-line;
  margin-bottom: 20px;
  color: ${palette.navy600};
  display: flex;
  align-items: center;
  text-align: center;
`;
const PreviousIcon = styled.img`
  width: 24px;
  height: 24px;
`;
const NextIcon = styled.img`
  width: 24px;
  height: 24px;
`;
const BottomContainer = styled.div`
  flex: 1;
  width: 340px;
  @media only screen and (min-width: 768px) {
    width: 550px;
  }
`;
const mapStateToProps = ({ uiReducer }) => ({
  isFetching: uiReducer.isFetching,
  showBanner: uiReducer.showBanner,
});
export default connect(mapStateToProps, {
  setIsFetching,
  AppointmentCalendarInteraction,
  AppointmentBookTimeScroll,
  AppointmentBookTimeSelect,
  AppointmentBookBooked,
  AppointmentBookGoBack,
  createTrackEvent,
  identifyPatientSegment,
  userSubmitEvent,
  trackOnLoadedScreenEvent,
})(AppointmentCalendar);
