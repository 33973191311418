import React, { useContext, useEffect, useRef, useState } from 'react';
import { Auth, API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { useFormik } from 'formik';

import { AppointmentContext } from '../utils/context';
import getUrlParameter from '../utils/getUrlParameter';

import {
  ConfirmAppointmentConfirmed,
  ConfirmAppointmentReschedule,
  createTrackEvent,
  TrackToStripeCheckoutSession,
  symptomsEnteredEvent,
  userBackButtonEvent,
  userSubmitEvent,
  trackOnLoadedScreenEvent,
} from '../actions/segment';
import { setIsFetching } from '../actions';
import {
  ONBOARDING_APPT_TYPE,
  REFERRAL_APPT_TYPE,
} from '../constants/appointmentTypes';
import { INSURED_FLOW } from '../constants/subscriptionStatus';
import { localStorageSave, localStorageGet } from '../utils/localStorageHelper';
import { stripePromise } from '../App';
import MembershipTransition from './MembershipTransition';
import useComponentFocus from '../hooks/useComponentFocus';
import AnimatedRoute from '../components/AnimatedRoute';
import OshiProviderInfoContainer from '../components/OshiProviderInfoContainer';
import OshiNextButton from '../components/OshiNextButton';
import usePatientAPI from '../hooks/usePatientAPI';
import SymptomsTextArea from '../components/SymptomsTextArea';
import useBookApptFlowSegmentTrackerOnPageView from '../hooks/useBookApptFlowSegmentTrackerOnPageView';
import useSegmentTracker from '../hooks/useSegmentTracker';

const success_url =
  window.location.hostname === 'localhost'
    ? `http://localhost:3000/first-appointment-overview`
    : `https://${window.location.hostname}/first-appointment-overview`;
const cancel_url =
  window.location.hostname === 'localhost'
    ? `http://localhost:3000/membership-cash-pay`
    : `https://${window.location.hostname}/membership-cash-pay`;

const AppointmentConfirm = ({
  ConfirmAppointmentConfirmed,
  TrackToStripeCheckoutSession,
  createTrackEvent,
  symptomsEnteredEvent,
  setIsFetching,
  userBackButtonEvent,
  trackOnLoadedScreenEvent,
  userSubmitEvent,
}) => {
  const history = useHistory();
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [appointment] = useContext(AppointmentContext);
  const {
    provider_id,
    available_from,
    available_to,
    provider_name,
    stripe_id,
    provider_role,
  } = appointment.AppointmentTime;
  const { subscriptionStatus } = localStorageGet('subscriptionStatus');
  const native = getUrlParameter('platform', history) === 'native';
  const { appointmentType: appointmentTypeFromWebview } = localStorageGet(
    'appointmentType'
  );
  const appointmentTypeIdFromWebview = getUrlParameter('appt_type', history);
  const userIdFromWebview = getUrlParameter('uid', history);
  const [showTransionToBillinScene, setShowTransionToBillinScene] = useState(
    false
  );
  const [scaleFactor, setScaleFactor] = useState(1);
  const [headlineRef, setHeadlineFocus] = useComponentFocus();
  const { customPatientUpdate } = usePatientAPI();
  const { trackSegmentFn: trackOnSubmit } = useSegmentTracker(
    appointmentTypeFromWebview,
    userSubmitEvent
  );
  const { trackSegmentFn: trackOnBackButton } = useSegmentTracker(
    appointmentTypeFromWebview,
    userBackButtonEvent
  );

  useBookApptFlowSegmentTrackerOnPageView(
    appointmentTypeFromWebview,
    trackOnLoadedScreenEvent
  );

  const didComeFromAppointmentOverview =
    getUrlParameter('from_location', history) === 'appointmentOverview';
  const stripeSession = useRef();
  const scaleRef = useRef();

  const formik = useFormik({
    initialValues: {
      symptoms: '',
    },
  });

  const enterSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleReserveAppt();
    }
  };

  const symptomsChangeSegmentTrack = () => {
    formik.values.symptoms.trim().length > 0 && symptomsEnteredEvent();
  };

  const handleSymptomsChange = (ev) => {
    formik.handleChange(ev);
  };

  const handleBackButtonEvent = () =>
    trackOnBackButton(
      appointmentTypeFromWebview === REFERRAL_APPT_TYPE && provider_role
        ? provider_role
        : null
    );

  async function startStripeSession() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const salesForcePatient = await API.get(
        'oshiAPI',
        `/users/${cognitoUser.username}`
      );
      const body = {
        customer: salesForcePatient.customer_id,
        payment_method_types: ['card'],
        line_items: [
          {
            price: 'price_1HLEh7GRkSLUmgd6WNWvtSrk',
            quantity: 1,
          },
        ],
        mode: 'subscription',
        subscription_data: {
          coupon: 'RaTa7qKh',
        },
        success_url,
        cancel_url,
      };

      stripeSession.current = await API.post('oshiAPI', '/billing/session', {
        body,
      });

      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log('error', { error });
    }
  }

  useEffect(
    () => {
      // if user refreshed while on this page or didn't
      // have an appt then go back to cal
      if (!appointment.Appointment && !didComeFromAppointmentOverview) {
        return history.replace(
          native
            ? `first-appointment-book${history.location.search}`
            : 'first-appointment-book'
        );
      } else if (didComeFromAppointmentOverview && !appointment.Appointment) {
        return history.goBack();
      }

      // eslint-disable-next-line no-undef
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      if (!didComeFromAppointmentOverview && !native) {
        startStripeSession();
      }

      if (window.innerHeight < 812 || window.innerWidth < 360) {
        setScaleFactor(Math.min(window.innerHeight / scaleRef.current.scrollHeight, 1));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    headlineRef.current && setHeadlineFocus(headlineRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeadlineFocus]);

  async function sendUserToCheckout() {
    setIsFetching(true);
    const stripe = await stripePromise;

    const result = await stripe.redirectToCheckout({
      sessionId: stripeSession.current.id,
    });

    TrackToStripeCheckoutSession();

    if (result.error) {
      console.log('result here', result);
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  }

  async function handleReserveAppt() {
    try {
      setIsFetching(true);
      if (formik.values.symptoms.length >= 3) {
        await customPatientUpdate(
          {
            symptoms: formik.values.symptoms,
          },
          userIdFromWebview
        );
      }

      const cognitoUser = native ? null : await Auth.currentAuthenticatedUser();
      const userIdForApiCall = native
        ? userIdFromWebview
        : cognitoUser.attributes.sub;
      ConfirmAppointmentConfirmed();

      const createAppointmentBody = {
        provider_id,
        from_date_time: available_from,
        to_date_time: available_to,
        provider_role,
        appointment_type_id:
          native && appointmentTypeFromWebview !== ONBOARDING_APPT_TYPE
            ? appointmentTypeIdFromWebview
            : ONBOARDING_APPT_TYPE,
        price_id: stripe_id,
        patient_cognito_id: userIdForApiCall,
        provider_name,
        appointmentDate: appointment.AppointmentDate,
        appointmentDuration: appointment.AppointmentTime.duration,
      };

      trackOnSubmit(
        appointmentTypeFromWebview === REFERRAL_APPT_TYPE && provider_role
          ? provider_role
          : null
      );
      symptomsChangeSegmentTrack();

      localStorageSave('reservedAppointment', createAppointmentBody);
      setIsFetching(false);
      if (native) {
        history.push(`/first-appointment-overview${history.location.search}`);
      } else if (didComeFromAppointmentOverview) {
        history.push(
          '/first-appointment-overview?from_location=appointmentOverview'
        );
      } else {
        if (INSURED_FLOW.includes(subscriptionStatus)) {
          history.push(`/first-appointment-overview`);
        } else {
          setShowTransionToBillinScene(true);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  if (showTransionToBillinScene) {
    return <MembershipTransition animationFinishAction={sendUserToCheckout} />;
  }
  
  return (
    <ScaledContainer ref={ scaleRef } scaleFactor={ scaleFactor }>
      <AnimatedRoute
        nextRoute='/first-appointment-confirm'
        title="You're almost there!"
        trackBackButtonSegmentEventFn={handleBackButtonEvent}
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
      >
        <SubHeaderContainer>
          <SubHeader>
            There's one last step to complete your booking.
          </SubHeader>
        </SubHeaderContainer>
        <OshiProviderInfoContainer
          appointmentDate={{
            time: moment(appointment.AppointmentTime.available_from).format('LT'),
            date: moment(appointment.AppointmentDate).format(
              `dddd${','} MMMM D${','} YYYY`
            ),
          }}
          duration={appointment.AppointmentTime.duration}
          title={provider_name}
          subTitle={provider_role}
        />

        <SymptomsTextArea
          native={native}
          appointmentTypeFromWebview={appointmentTypeFromWebview}
          formik={formik}
          handleSymptomsChange={handleSymptomsChange}
          enterSubmit={enterSubmit}
        />

        <OshiNextButton
          buttonTitle='Book Appointment'
          onClick={handleReserveAppt}
        />
      </AnimatedRoute>
    </ScaledContainer>
  );
};

const ScaledContainer = styled.div.attrs(props => props)`
  transform-origin: top;
  transform: scale(${props => props.scaleFactor});
`;

const SubHeader = styled.p`
  text-align: left;
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #66727f;
`;

const SubHeaderContainer = styled.div`
  display: flex;
  margin-top: -30px;
  margin-bottom: 26px;
  width: 100%;
`;

const mapStateToProps = ({ uiReducer }) => ({
  isFetching: uiReducer.isFetching,
});
export default connect(mapStateToProps, {
  ConfirmAppointmentConfirmed,
  ConfirmAppointmentReschedule,
  createTrackEvent,
  symptomsEnteredEvent,
  TrackToStripeCheckoutSession,
  setIsFetching,
  userBackButtonEvent,
  userSubmitEvent,
  trackOnLoadedScreenEvent,
})(AppointmentConfirm);
