import React from 'react';
import styled from 'styled-components';

import Button from './Button';

const OshiLink = React.forwardRef(
  ({ onClick, buttonTitle, styles = {} }, ref) => {
    const mergedStyles = {
      ...styles,
      backgroundColor: 'transparent',
    }
    return (
      <Container>
        <Button btnAs='link' onClick={onClick} styles={mergedStyles} ref={ref}>
          {buttonTitle}
        </Button>
      </Container>
    );
  }
);

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export default OshiLink;
