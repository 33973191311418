import React from 'react';
import styled from 'styled-components';

import Button from './Button';

const OshiNextButton = React.forwardRef(
  ({ disabled, onClick, buttonTitle, styles = {}, children = null }, ref) => {
    return (
      <Container>
        <NextButtonContainer>
          <Button
            disabled={disabled}
            onClick={onClick}
            styles={styles}
            ref={ref}
          >
            {buttonTitle}
          </Button>
          {children}
        </NextButtonContainer>
      </Container>
    );
  }
);

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 20px;

  @media only screen and (min-width: 429px) {
    align-items: flex-start;
    padding-botton: 0;
  }
`;
const NextButtonContainer = styled.div`
  display: flex;
  margin: 10px 0 20px 0px;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 429px) {
    justify-content: left;
    margin: 42px 0 20px 0px;
  }
`;

export default OshiNextButton;
