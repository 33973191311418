/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { palette } from '../theme/palette';
import ExpandableComponent from '../components/ExpandableComponent';
import Typography from '../atoms/Typography';
import withTracker from '../wrappers/withTracker';
import {
  createTrackEvent,
  identifyPatientSegment,
  userPrivacyPolicySubmitEvent,
} from '../actions/segment';
import { segmentTypes } from '../constants';
import { setIsFetching } from '../actions';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import { signUpRoutes } from '../routes';
import phases from '../constants/phases';
import { OPTUM_SUBSCRIPTION_STATUS } from '../constants/subscriptionStatus';
import usePatientAPI from '../hooks/usePatientAPI';
import { PATIENT_LEAD_STATUSES } from '../constants/salesforceTypes';
import {
  termsOfUse,
  privacyPolicy,
  privacyPractices,
  informedConsent,
  assignmentBenefits,
} from '../constants/privacyPolicyInfo';
import AnimatedRoute from '../components/AnimatedRoute';
import OshiNextButton from '../components/OshiNextButton';

function PrivacyPolicy({
  createTrackEvent,
  setIsFetching,
  isFetching,
  identifyPatientSegment,
  userPrivacyPolicySubmitEvent: userPrivacyPolicySubmit,
}) {
  const partner = localStorageGet('partner');
  const history = useHistory();
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [checkedPolicies, setCheckedPolicies] = useState([
    {
      title: 'Terms of Use',
      text: termsOfUse,
      key: 'TOU',
      isChecked: false,
      error: false,
      isExpanded: false,
    },
    {
      title: 'Privacy Policy',
      text: privacyPolicy,
      key: 'PP',
      isChecked: false,
      error: false,
      isExpanded: false,
    },
    {
      title: 'Informed Consent',
      text: informedConsent,
      key: 'IC',
      isChecked: false,
      error: false,
      isExpanded: false,
    },
    {
      title: 'Assignment of Benefits',
      text: assignmentBenefits,
      key: 'AOB',
      isChecked: false,
      error: false,
      isExpanded: false,
    },
    {
      acknowledgement: true, // used to display "And I acknowledge the receipt of"
      title: 'Notice of Privacy Practice',
      text: privacyPractices,
      key: 'NPP',
      isChecked: false,
      error: false,
      isExpanded: false,
    },
  ]);
  const {
    signIn,
    signUp,
    createStripeAccount,
    createPatient,
  } = usePatientAPI();

  const areAllPoliciesChecked =
    checkedPolicies.filter((policy) => !policy.isChecked).length <= 0;
  const isThereAnError =
    checkedPolicies.filter((policy) => policy.error).length > 0;

  useEffect(() => {
    const routeUserShouldBeOn = localStorageGet('currentRoute');
    const didCompleteSignUpFlow = localStorageGet('didCompleteSignUpFlow');
    const route = history.location.pathname;
    if (didCompleteSignUpFlow && signUpRoutes[route]) {
      history.push(routeUserShouldBeOn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function updateCheckedPolicies(key) {
    const checkedPoliciesClone = [...checkedPolicies];

    checkedPoliciesClone.forEach((policy) => {
      if (policy.key === key) {
        !policy.isChecked &&
          createTrackEvent(
            segmentTypes.PRIVACY_CONTAINER_ACCEPTED,
            `${
              policy.title !== 'Notice of Privacy Practice' ? 'Privacy ' : ''
            }${policy.title} Accepted`
          );
        policy.isChecked = !policy.isChecked;

        if (policy.error) {
          policy.error = false;
        }
      }
    });

    setCheckedPolicies(checkedPoliciesClone);
  }

  function handleExpandClick(key) {
    const checkedPoliciesClone = [...checkedPolicies];

    checkedPoliciesClone.forEach((policy) => {
      policy.isExpanded = policy.key === key && !policy.isExpanded;

      if (policy.error && policy.key === key) {
        policy.error = false;
      }

      if (policy.key === key && policy.isExpanded) {
        // send segment even if opened
        createTrackEvent(
          segmentTypes.PRIVACY_CONTAINER_EXPANDED,
          `Privacy ${policy.title} Expanded`
        );
      }
    });

    setCheckedPolicies(checkedPoliciesClone);
  }

  function handleExpandableComponentScroll(event, key) {
    const checkedPoliciesClone = [...checkedPolicies];
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (!bottom) return;

    checkedPoliciesClone.forEach((policy) => {
      if (policy.key === key) {
        createTrackEvent(
          segmentTypes.PRIVACY_CONTAINER_SEEN,
          `Privacy ${policy.title} Seen`
        );
      }
    });
  }

  async function handleNext() {
    if (!areAllPoliciesChecked) {
      const checkedPoliciesClone = [...checkedPolicies];

      checkedPoliciesClone.forEach((policy) => {
        if (!policy.isChecked) {
          policy.error = true;
        }
      });

      return setCheckedPolicies(checkedPoliciesClone);
    }

    const email = localStorageGet('email');
    const { firstName, lastName } = localStorageGet('name');
    const { pimid } = localStorageGet('pimid');
    const { qstring } = localStorageGet('qstring');
    const { subscriptionStatus } = localStorageGet('subscriptionStatus');
    const state = localStorageGet('state');
    const { location } = history;
    let lowerCaseEmail;
    email ? (lowerCaseEmail = email.toLowerCase()) : (lowerCaseEmail = '');
    const { password } = location.state;
    // there is a possibility that a user will land
    // on this route before filling out the needed info
    // if no password is passed to this route
    // location && location.state will be undefined/false
    // we also need to check for email and firstName, lastName
    if (email && location && location.state && firstName && lastName) {
      try {
        // Todo: Trigger Loader
        setIsFetching(true);
        const cognitoUser = await signUp({
          email: lowerCaseEmail,
          firstName,
          lastName,
          password,
        });

        // need to sign the user in to make calls to the endpoint
        await signIn({
          email: lowerCaseEmail,
          password,
        });
        const stripeAccount = await createStripeAccount({
          userSub: cognitoUser.userSub,
          email: lowerCaseEmail,
          firstName,
          lastName,
        });

        // creates the patient
        const sfPatientPRofile = await createPatient({
          lastName,
          firstName,
          email: lowerCaseEmail,
          cognitoId: cognitoUser.userSub,
          customerId: stripeAccount.id,
          subscriptionStatus: OPTUM_SUBSCRIPTION_STATUS,
          pimdId: pimid,
          qstring,
          leadStatus: PATIENT_LEAD_STATUSES.CONVERTED,
          phase: phases.ONBOARDING,
          state,
        });

        identifyPatientSegment(sfPatientPRofile.sf_id, {
          emailAddress: email,
          firstName,
          lastName,
          subscribedStatus: subscriptionStatus,
          partner,
        });

        localStorageSave(
          'patientIdentifiedThroughSegment',
          sfPatientPRofile.sf_id
        );

        createTrackEvent(segmentTypes.PRIVACY_PAGE_EXIT, `Privacy Page Exit`);

        userPrivacyPolicySubmit();
        // this only gets hit when a user leaves the route and
        // submitted the needed info. So we store the next route
        // to keep track of where the user should be redirected to
        localStorageSave('currentRoute', '/get-symptom-relief'); // we store first-appointment-book because we don't want the user going back
        // we also store that the user completed the signUp flow
        localStorageSave('didCompleteSignUpFlow', 'true');
        setIsFetching(false);
        history.push('/get-symptom-relief');
      } catch (error) {
        createTrackEvent(
          segmentTypes.SERVER_ERROR,
          `Account Creation Server error`,
          {
            error: {
              error,
              email: lowerCaseEmail,
            },
          }
        );
        setIsFetching(false);
        history.push('account-creation-error', {
          error,
        });
      }
    } else {
      // TODO: we will handle this error differently
      // once BE ENG comes in and adds the user endpoint
      createTrackEvent(segmentTypes.ERROR, `Form was not completed correctly`);
    }
  }

  return (
    <AnimatedRoute
      title='Terms &<mbr /> Conditions'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <PContainer>
        <Paragraph>Please review our care and privacy policies.</Paragraph>
      </PContainer>

      <AgeContainer>
        <AgeText>
          I am at least 18 years of age and I have read and accepted:
        </AgeText>
      </AgeContainer>

      <ExpandableComponentContainer>
        {checkedPolicies.map((policy, index) => {
          return (
            <Fragment key={policy.key}>
              {policy.acknowledgement && (
                <AcknowledgementContainer>
                  <Acknowledge type='boldSmall'>
                    And acknowledge the receipt of:
                  </Acknowledge>
                </AcknowledgementContainer>
              )}

              <ExpandableComponent
                error={policy.error}
                handleCheckOnClick={updateCheckedPolicies}
                isExpanded={policy.isExpanded}
                onClick={handleExpandClick}
                onScroll={handleExpandableComponentScroll}
                title={policy.title}
                componentKey={policy.key}
                isChecked={policy.isChecked}
              >
                <Typography type='subcopy'>{policy.text}</Typography>
              </ExpandableComponent>
            </Fragment>
          );
        })}

        {isThereAnError && (
          <ErrorContainer>
            <Typography type='subcopy'>
              You must accept the&nbsp;
              <Typography type='boldSmall'>Terms of Use</Typography>,&nbsp;
              <Typography type='boldSmall'>Privacy Policy</Typography>
              ,&nbsp;
              <Typography type='boldSmall'>Informed Consent</Typography>
              ,&nbsp;and&nbsp;
              <Typography type='boldSmall'>Assignment of Benefits</Typography>;
              and acknowledge receipt of the&nbsp;
              <Typography type='boldSmall'>
                Notice of Privacy Practice
              </Typography>
              &nbsp;in order to continue.
            </Typography>
          </ErrorContainer>
        )}
      </ExpandableComponentContainer>

      <OshiNextButton onClick={handleNext} buttonTitle='Create Your Account' />
    </AnimatedRoute>
  );
}

const ExpandableComponentContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 12px;
  flex-direction: column;
  white-space: pre-wrap;
  width: 100%;
`;

const AcknowledgementContainer = styled.div`
  display: flex;
  padding: 24px 0px 12px 0px;
`;

const Acknowledge = styled.span`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: ${palette.coolGray};
`;

const ErrorContainer = styled.div`
  display: flex;
  padding: 10px 0px 5px;
  color: ${palette.error};
`;

const PContainer = styled.div`
  display: flex;
  max-width: 100%;
  margin-bottom: 24px;
  margin-top: -30px;
  width: 100%;
`;

const Paragraph = styled.p`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #66727f;
  width: 251px;
  @media only screen and (min-width: 429px) {
    width: 100%;
  }
`;

const AgeContainer = styled.div`
  width: 100%;
`;

const AgeText = styled.p`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: ${palette.coolGray};
`;

const mapStateToProps = ({ uiReducer }) => ({
  isFetching: uiReducer.isFetching,
});
export default connect(mapStateToProps, {
  createTrackEvent,
  setIsFetching,
  identifyPatientSegment,
  userPrivacyPolicySubmitEvent,
})(withTracker(PrivacyPolicy, 'Signup - Privacy - Page View'));
