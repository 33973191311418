import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './aws-exports';
import runAxe from './react-axe.config';

ReactGA.initialize(process.env.GA_KEY);

runAxe();

Sentry.init({
  dsn:
    'https://b5d7a30867ea495898589e78f3da67d5@o429510.ingest.sentry.io/5376324',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.ENV || 'dev',
});

Amplify.configure(config);
ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
