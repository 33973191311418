/* eslint-disable default-case */
import React from 'react';
import styled from 'styled-components';

import { palette } from '../theme/palette';
import {
  HeaderLogoCicrle,
  HeaderLogo,
  ArrowRight,
  ArrowLeft,
  CloseIcon,
} from '../assets/svgs';

function OshiHeader({ type, action, segment, testId, logoType }) {
  const backHandler = () => {
    action();
    if (segment) {
      segment();
    }
  };
  return (
    <Container data-testid={testId || ''}>
      <PaddedContainer>
        <LeftIconContainer onClick={backHandler}>
          {type === 'back' && <ArrowLeft color={palette.greyText} />}
        </LeftIconContainer>

        <LeftIconContainer onClick={backHandler}>
          {type === 'close' && <CloseIcon color={palette.greyText} />}
        </LeftIconContainer>
        {logoType === 'circle' ? <HeaderLogoCicrle /> : <HeaderLogo />}

        <RightIconContainer onClick={action}>
          {type === 'forward' && <ArrowRight color={palette.greyText} />}
        </RightIconContainer>
      </PaddedContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  z-index: 1;
  flex: 1;
  width: 100%;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
`;

const PaddedContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  margin: 0 15px;
  flex-direction: column;
  position: relative;
  max-width: 600px;
  align-items: center;
  justify-content: center;
`;

const LeftIconContainer = styled.div`
  position: absolute;
  left: 0;
  display: none;
  @media (max-width: 420px) {
    display: flex;
  }
`;

const RightIconContainer = styled.div`
  position: absolute;
  right: 0;
`;

export default OshiHeader;
