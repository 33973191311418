import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { localStorageSave, localStorageGet } from '../utils/localStorageHelper';
import { stateAbbreviations } from '../constants/stateAbbreviations';
import useEligibleStateUIForm from './useEligibleStateUIForm';
import useZipEligibilityUILogic from './useZipEligibilityUILogic';
import usePatientLeadAPI from './usePatientLeadAPI';
import { PATIENT_LEAD_STATUSES } from '../constants/salesforceTypes';

/**
 * Custom hook to handle UI logic processing for /state UI page.
 *
 * @param { Object } uiLogicConfig - Set of properties/methods to handle for instance tracking segment analytics
 * and custom UI state changes.
 * @returns { Object } uiLogicParams - Set of properties/methods to handle all UI logic behind /state page
 * For this case, UI form logic and UI navigation logic configuration are sent back to UI component to be used in its
 * UI template.
 */
const useEligibleStatePageUILogic = ({ segments, setIsFetching }) => {
  const {
    ZipcodeSubmit,
    ZipcodeInvalid,
    OffBoardingAvailableStateDebug,
    ZipcodeNoCoverage,
    OffBoardingDebug,
  } = segments;
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const history = useHistory();
  const [zipInvalid, setZipInvalid] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { availableStates, zipLookUp } = useZipEligibilityUILogic(
    setIsFetching
  );
  const { savePatientLead } = usePatientLeadAPI();
  const partner = localStorageGet('partner');

  const handleSubmitFn = async (values) => {
    setIsFetching(true);
    localStorageSave('zipcode', values);
    ZipcodeSubmit();
    try {
      const locationObject = await zipLookUp(values.zipcode);
      if (locationObject === 'invalid') {
        ZipcodeInvalid();
        setIsFetching(false);
        setZipInvalid(true);
        return;
      }
      const stateAbbreviation = stateAbbreviations[locationObject.state];
      OffBoardingAvailableStateDebug(locationObject.state, {
        availableStates,
        stateAbbreviation,
      });
      setAnimationDirection('unmount');
      if (!availableStates.includes(stateAbbreviation)) {
        ZipcodeNoCoverage();
        OffBoardingDebug(locationObject.state, {
          submitted_zip: values.zipcode,
          returned_zip: locationObject.zip5,
          returned_city: locationObject.city,
          returned_state: locationObject.state,
        });
        setIsFetching(false);
        history.push({
          pathname: '/offboarding',
          state: {
            zipcode: values.zipcode,
          },
        });
      } else {
        localStorageSave('state', {
          state: stateAbbreviations[locationObject.state],
        });
        await savePatientLead('update', {
          zip: values.zipcode,
          status: PATIENT_LEAD_STATUSES.QUALIFIED,
          partner,
        });
        setIsFetching(false);
        history.push('/password');
      }
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  const handleOnKeyPressFn = (evt, formik) => {
    if (evt.keyCode === 13) {
      if (formik.values.zipcode.length === 5 && !zipInvalid) {
        handleSubmitFn(formik.values);
      }
      evt.preventDefault();
    }
  };

  const { formik, handleOnKeyPress } = useEligibleStateUIForm({
    handleSubmitFn,
    handleOnKeyPressFn,
  });

  useEffect(() => {
    if (zipInvalid && formik.values.zipcode.length < 5) {
      setZipInvalid(false);
    }
    if (formik.values.zipcode.length > 1 && formik.isValid && disabled) {
      setDisabled(false);
    }
    if ((!disabled && !formik.isValid) || availableStates.length === 0) {
      setDisabled(true);
    }
  }, [
    zipInvalid,
    formik.values.zipcode.length,
    formik.isValid,
    disabled,
    availableStates,
  ]);

  return {
    formik,
    animationDirection,
    setAnimationDirection,
    zipInvalid,
    disabled,
    handleOnKeyPress,
  };
};

export default useEligibleStatePageUILogic;
