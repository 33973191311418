import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';
import { setIsFetching } from '../actions';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import withTracker from '../wrappers/withTracker';
import {
  ZipcodeSubmit,
  ZipcodeNoCoverage,
  ZipcodeInvalid,
  OffBoardingDebug,
  OffBoardingAvailableStateDebug,
} from '../actions/segment';
import useEligibleStatePageUILogic from '../hooks/useEligibleStatePageUILogic';

function EligibleStateCheck({
  ZipcodeSubmit,
  ZipcodeNoCoverage,
  ZipcodeInvalid,
  setIsFetching,
  OffBoardingDebug,
  OffBoardingAvailableStateDebug,
}) {
  const {
    formik,
    animationDirection,
    setAnimationDirection,
    zipInvalid,
    disabled,
    handleOnKeyPress,
  } = useEligibleStatePageUILogic({
    segments: {
      ZipcodeSubmit,
      ZipcodeNoCoverage,
      ZipcodeInvalid,
      OffBoardingDebug,
      OffBoardingAvailableStateDebug,
    },
    setIsFetching,
  });

  return (
    <AnimatedRoute
      nextRoute='/password'
      title='Where will you be receiving care from?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form onKeyDown={handleOnKeyPress}>
        <SubcopyContainer>
          <TypographyContainer>
            <Typography
              styles={{ color: palette.darkSecondaryText }}
              type='subcopy'
            >
              In order to receive care from us, we will need to connect you with
              a licensed provider in your state.
            </Typography>
          </TypographyContainer>
        </SubcopyContainer>
        <OshiInput
          id='zipcode'
          type='tel'
          label='Zip Code'
          maxChar={5}
          onChange={formik.handleChange}
          formik={formik}
          segmentLabel='Onboarding - Patient Location - Zip Code Entered'
          value={formik.values.zipcode}
          showCheckMark={formik.values.zipcode > 4 && !zipInvalid}
          error={zipInvalid}
        />
        <LocationContainer />
        <ErrorContainer>
          {zipInvalid && (
            <Typography styles={{ color: palette.error }}>
              Zipcode is invalid
            </Typography>
          )}
        </ErrorContainer>
      </Form>

      <NextButtonContainer>
        <Button type='submit' disabled={disabled} onClick={formik.handleSubmit}>
          Continue
        </Button>
      </NextButtonContainer>
    </AnimatedRoute>
  );
}

const Form = styled.form`
  min-height: 195px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubcopyContainer = styled.div`
  display: flex;
  flex: 1;
  width: 95%;
  justify-content: center;
  align-item: center;
  margin-top: -70px;
`;

const TypographyContainer = styled.div`
  margin: 20px 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const LocationContainer = styled.div`
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 50px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex: 1;
  justify-content: center;
`;
const ErrorContainer = styled.div`
  position: relative;
  top: -125px;
  display: flex;
  height: 40px;
  text-align: center;
  justify-content: center;
  padding: 0px 20px 20px 20px;
`;

export default connect(null, {
  ZipcodeSubmit,
  ZipcodeInvalid,
  ZipcodeNoCoverage,
  setIsFetching,
  OffBoardingDebug,
  OffBoardingAvailableStateDebug,
})(
  withTracker(
    EligibleStateCheck,
    'Onboarding - Patient Location - State Eligibility Page'
  )
);
