/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import withTracker from '../wrappers/withTracker';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import { localStorageSave, localStorageGet } from '../utils/localStorageHelper';
import OshiHeader from '../components/OshiHeader';
import Button from '../components/Button';
import OnboardingImage from '../assets/off-boarding.png';
import usePatientLeadAPI from '../hooks/usePatientLeadAPI';
import { PATIENT_LEAD_STATUSES } from '../constants/salesforceTypes';

import {
  userZipOffboardingBackButtonEvent,
  userZipOffboardingSubmitEvent,
} from '../actions/segment';

const OffBoarding = ({
  userZipOffboardingBackButtonEvent: userZipOffboardingBackButton,
  userZipOffboardingSubmitEvent: userZipOffboardingSubmit,
}) => {
  const { savePatientLead } = usePatientLeadAPI();
  const { email } = localStorageGet('email');
  const { lead } = localStorageGet('lead');
  const partner = localStorageGet('partner');
  const history = useHistory();

  useEffect(() => {
    if (!lead) {
      localStorageSave('lead', { lead: email });
    }

    if (
      history.location &&
      history.location.state &&
      history.location.state.address
    ) {
      const { zip, apartment, streetAddress} = history.location.state.address;
      const updateLead = {
        zip_code: zip,
        partner,
        status: PATIENT_LEAD_STATUSES.UNQUALIFIED,
        street: streetAddress,
      };

      if (apartment) {
        updateLead.address2 = apartment;
      }

      savePatientLead('update', updateLead);
    } else {
      history.goBack();
    }

    // eslint-disable-next-line
  }, [lead]);

  function handleBackArrow() {
    history.goBack();
  }

  return (
    <PageContainer>
      <Background />
      <OshiHeader
        type='back'
        action={handleBackArrow}
        segment={userZipOffboardingBackButton}
      />
      <FlexContainer>
        <div>
          <OFImage
            src={OnboardingImage}
            alt='two hands reaching for eachother'
          />
        </div>
        <PageTitle>
          <Typography
            type='boldRegular'
            styles={{
              color: palette.darkPrimaryText,
              fontFamily: 'Source Serif Pro, serif',
            }}
          >
            Thanks for your interest!
          </Typography>
        </PageTitle>

        <ParagraphContainer>
          <Typography
            type='default'
            styles={{ color: palette.darkPrimaryText, marginBottom: '22px' }}
          >
            At the moment, we’re not able to provide care in your state.
          </Typography>

          <Typography
            type='default'
            styles={{ color: palette.darkPrimaryText }}
          >
            If you believe you’ve received this in error please contact our Care
            Support.
          </Typography>
        </ParagraphContainer>
        <Typography
          type='boldSmall'
          styles={{
            color: palette.darkPrimaryText,
            textDecoration: 'underline',
            fontSize: '14px',
            letterSpacing: '0.06em',
            marginTop: '5px',
            cursor: 'pointer',
          }}
        >
          <Button
            onClick={() => {
              userZipOffboardingSubmit();
              window.location.href = 'mailto:carecoordinator@oshihealth.com';
            }}
          >
            Contact Support
          </Button>
        </Typography>
      </FlexContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: static;
  width: 100vw;
  height: 100vh;
`;
const Background = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  background: linear-gradient(180deg, #fff 0%, #f4faf8 100%);
`;
const FlexContainer = styled.div`
  position: relative;
  @media (max-width: 420px) {
    top: 12vh;
  }
  top: 16vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  z-index: 2;
`;
const PageTitle = styled.div`
  width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 56px 0;
`;
const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 168px;
  text-align: center;
  margin-bottom: -25px;
  max-width: 420px;
  padding: 0 10px;
  @media (max-width: 420px) {
    margin-bottom: 0;
  }
`;
const OFImage = styled.img`
  height: 200px;
  width: 200px;
`;

export default connect(null, {
  userZipOffboardingBackButtonEvent,
  userZipOffboardingSubmitEvent,
})(withTracker(OffBoarding, 'Signup - Zip Offboarding - Page View'));
