import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import { userTypes } from '../constants';

function usePatientProfile(native = false) {
  const patientProfileRedux = useSelector(
    (state) => state.userReducer.patientProfile
  );
  const [patientProfile, setPatientProfile] = useState(patientProfileRedux);
  const dispatch = useDispatch();

  async function getPatientProfile() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const userId = cognitoUser.attributes.sub;
      const salesForcePatient = await API.get('oshiAPI', `/users/${userId}`);
      dispatch({
        type: userTypes.STORE_PATIENT_PROFILE,
        patientProfile: salesForcePatient,
      });
      setPatientProfile(salesForcePatient);
    } catch (error) {
      setPatientProfile(false);
    }
  }

  useEffect(
    () => {
      if (!patientProfile && !native) getPatientProfile();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return patientProfile;
}
export default usePatientProfile;
