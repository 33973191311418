import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { useChain } from 'react-spring';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AnimatedTitle from '../atoms/AnimatedTitle';
import AnimatedContent from '../atoms/AnimatedContent';
import { palette } from '../theme/palette';
import OshiHeader from './OshiHeader';
import OshiProgressBar from './OshiProgressBar';
import { localStorageSave, localStorageGet } from '../utils/localStorageHelper';
import { userBackButtonEvent } from '../actions/segment';
import { signUpRoutes } from '../routes';

function AnimatedRoute(props) {
  const {
    title,
    children,
    progressPercentage,
    nextRoute,
    animationDirection,
    trackBackButtonSegmentEventFn = null,
    userBackButtonEvent,
    setAnimationDirection,
    nextRouteProps = {}, // these are props you can pass on to the next route
  } = props;
  const titleAnimeRef = useRef();
  const contentAnimeRef = useRef();
  const history = useHistory();
  const [resetAnimaions] = useState(false);
  const [
    cameAfterOshiHeaderBackButtonClick,
    setCameAfterOshiHeaderBackButtonClick,
  ] = useState(false);
  const cameAfterBrowserBackButtonClick = history.action === 'POP';
  const didCompleteSignUpFlow = localStorageGet('didCompleteSignUpFlow');
  const route = history.location.pathname.split('/')[1];

  useEffect(() => {
    if (didCompleteSignUpFlow && signUpRoutes[route]) {
      // Re-route to gender if user already completed sign up flow
      // and is in a sign up route
      history.push('/get-symptom-relief');
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  function handleAnimationFinish(animation) {
    if (cameAfterOshiHeaderBackButtonClick) {
      history.goBack();
    } else if (animation.opacity === 0) {
      // this only gets hit when a user leaves the route and
      // submitted the needed info. So we store the next route
      // to keep track of where the user should be redirected to
      localStorageSave('currentRoute', nextRoute);
      history.push(nextRoute, nextRouteProps);
    }
  }

  const handleBackArrow = () => {
    setCameAfterOshiHeaderBackButtonClick(true);
    setAnimationDirection && setAnimationDirection('unmount');
  };

  function getHeader() {
    // some routes won't have the back arrow when
    // we don't want the user to go back
    // eslint-disable-next-line default-case
    switch (route) {
      case 'name':
      case 'first-appointment-book':
      case 'first-appointment-overview':
        return <OshiHeader action={handleBackArrow} />;
    }
    // default case;
    return (
      <OshiHeader
        action={handleBackArrow}
        segment={() =>
          trackBackButtonSegmentEventFn
            ? trackBackButtonSegmentEventFn()
            : userBackButtonEvent(`/${route}`)
        }
        type='back'
      />
    );
  }

  useChain([titleAnimeRef, contentAnimeRef], [0, 0.5]);

  return (
    <Container>
      {getHeader()}
      <PaddedContainer>
        {progressPercentage >= 0 ? (
          <OshiProgressBar
            cameAfterOshiHeaderBackButtonClick={
              cameAfterOshiHeaderBackButtonClick
            }
            progressPercentage={progressPercentage}
          />
        ) : null}

        <AnimatedTitle
          cameAfterBrowserBackButtonClick={cameAfterBrowserBackButtonClick}
          cameAfterOshiHeaderBackButtonClick={
            cameAfterOshiHeaderBackButtonClick
          }
          animationRef={titleAnimeRef}
          resetAnimaions={resetAnimaions}
          animationDirection={animationDirection}
          progressPercentage={progressPercentage}
          title={title}
        />

        <AnimatedContent
          style={{ flexDirection: 'column' }}
          cameAfterBrowserBackButtonClick={cameAfterBrowserBackButtonClick}
          animationRef={contentAnimeRef}
          resetAnimaions={resetAnimaions}
          animationDirection={animationDirection}
          handleAnimationFinish={handleAnimationFinish}
        >
          {children}
        </AnimatedContent>
      </PaddedContainer>
    </Container>
  );
}

const Container = styled.main`
  width: 100%;
  max-height: 100vh;
  min-height: 90vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: relative;
  justify-content: center;
`;

const PaddedContainer = styled.div`
  width: 90%;
  min-height: 100%;
  display: flex;
  flex: 1;
  margin: 0 24px;
  flex-direction: column;
  position: relative;
  max-width: 500px;
  align-items: center;
  padding-top: 50px;
`;

export default connect(null, { userBackButtonEvent })(AnimatedRoute);
