import { uiTypes } from '../constants';

const uiReducer = (
  state = {
    isFetching: false,
    showBanner: false,
  },
  action
) => {
  switch (action.type) {
    case uiTypes.IS_FETCHING:
      return { ...state, isFetching: action.isFetching };
    case uiTypes.SHOW_BANNER:
      return { ...state, showBanner: action.showBanner };
    default:
      return state;
  }
};

export default uiReducer;
