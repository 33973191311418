import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { API } from 'aws-amplify';

import { palette } from '../theme/palette';
import { AppointmentContext } from '../utils/context';
import appointmentStatuses from '../constants/appointmentStatuses';
import {
  AppointmentOverviewNote,
  AppointmentOverviewNoteSent,
  AppointmentOverviewNoNoteSent,
  AppointmentOverviewCoachBio,
  TrackMembershipConfirmation,
  TrackAppointmentTaken,
  TrackAppointmentTakenSheduled,
  trackBookAnotherAppointmentEvent,
  trackBackToAppointmentsEvent,
  trackOnLoadedScreenEvent,
} from '../actions/segment';

import { setIsFetching, setShowBanner } from '../actions';
import getUrlParameter from '../utils/getUrlParameter';
import { localStorageGet } from '../utils/localStorageHelper';
import usePatientProfile from '../hooks/usePatientProfile';
import {
  ONBOARDING_APPT_TYPE,
  REFERRAL_APPT_TYPE,
} from '../constants/appointmentTypes';
import AnimatedRoute from '../components/AnimatedRoute';
import OshiDownloadDisplay from '../components/OshiDownloadDisplay';
import OshiNextButton from '../components/OshiNextButton';
import OshiLink from '../components/OshiLink';
import { MOBILE_ACTION_KEYS } from '../constants/mobile';
import useBookApptFlowSegmentTrackerOnPageView from '../hooks/useBookApptFlowSegmentTrackerOnPageView';
import useSegmentTracker from '../hooks/useSegmentTracker';

const AppointmentOverview = (props) => {
  const history = useHistory();
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [, setAppointment] = useContext(AppointmentContext);

  //Local storage variables
  const reservedAppointment = localStorageGet('reservedAppointment');
  const { appointmentType } = localStorageGet('appointmentType');
  const {
    provider_id,
    provider_role,
    to_date_time,
    from_date_time,
    patient_cognito_id,
    appointment_type_id,
  } = reservedAppointment;

  //url variables user + appointment informaiton -- used for the native flow
  const native = getUrlParameter('platform', history) === 'native';
  const patientProfile = usePatientProfile(native);
  const userIdFromWebview = getUrlParameter('uid', history);
  const appointmentIdFromWebViewToReschedule = getUrlParameter(
    'appointmentId',
    history
  );
  const isRescheduleingFromWebView =
    getUrlParameter('reschedule', history) === 'true';

  const {
    setIsFetching,
    setShowBanner,
    trackBookAnotherAppointmentEvent,
    trackBackToAppointmentsEvent,
    trackOnLoadedScreenEvent,
  } = props;

  const { trackSegmentFn: trackOnBookAnotherAppointment } = useSegmentTracker(
    appointmentType,
    trackBookAnotherAppointmentEvent
  );

  const { trackSegmentFn: trackOnBackToAppointment } = useSegmentTracker(
    appointmentType,
    trackBackToAppointmentsEvent
  );

  useBookApptFlowSegmentTrackerOnPageView(
    appointmentType,
    trackOnLoadedScreenEvent
  );

  async function processAppointment() {
    try {
      if (native) {
        return processAppointmentNative();
      }

      //block for the cashpay users - does not apply to optum
      // it is here to ensure the user is successfully subscribed to stripe
      if (patientProfile !== 'Optum') {
        const userSubscriptionStatus = await API.get(
          'oshiAPI',
          `/users/subscribed?email=${encodeURIComponent(patientProfile.email)}`
        );

        //Why are we directing user back to book page if their status is unsubscribed?
        // we are directing the user back to the book page for them to go through the flow again in case the subscription was not successful on the first try
        if (!userSubscriptionStatus.subscribed) {
          // TODO: remove this when not doing cashpay
          // we shouldn't hit this case tho since users
          // really can't go through cashpay
          history.replace('first-appointment-book');
          return setIsFetching(false);
        }
      }

      // check to see if we have the details for
      // an appointment and we then reserve it  else
      // send them back to the cal
      if (reservedAppointment) {
        await API.post(
          'oshiAPI',
          `/users/${patientProfile.cognito_id}/appointments`,
          {
            body: {
              provider_id,
              from_date_time,
              to_date_time,
              patient_cognito_id,
              appointment_type: 'onboarding',
            },
          }
        );

        triggerSuccessfullBook();
        return setIsFetching(false);
      } else {
        history.replace('first-appointment-book');
        return setIsFetching(false);
      }
    } catch (error) {
      // trigger error booking onboarding appointment
      // and send them to the first appointment book page
      handleErrorAndSendToBooking();
    }
  }

  function triggerSuccessfullBook() {
    // clear local storage when we successfully book an appointment
    // clear context
    setAppointment();
    localStorage.clear();
  }

  const processAppointmentNative = async () => {
    try {
      const nativePayload = {
        provider_id,
        from_date_time,
        to_date_time,
        patient_cognito_id: userIdFromWebview,
      };
      appointmentType === ONBOARDING_APPT_TYPE
        ? (nativePayload.appointment_type = ONBOARDING_APPT_TYPE)
        : (nativePayload.appointment_type_id = appointment_type_id);

      if (isRescheduleingFromWebView) {
        // we want to add from_id when in the "rescheduleing flow"
        nativePayload.from_id = appointmentIdFromWebViewToReschedule;
      }

      // creates new appointment
      await API.post('oshiAPI', `/users/${userIdFromWebview}/appointments`, {
        body: nativePayload,
      });

      if (isRescheduleingFromWebView) {
        //change status of the appointment to reschedule if the appoitnmentn already exists
        await API.put(
          'oshiAPI',
          `/appointments/${appointmentIdFromWebViewToReschedule}`,
          {
            body: {
              appointment_status: appointmentStatuses.RESCHEDULED,
            },
          }
        );
      }
      triggerSuccessfullBook();
      return setIsFetching(false);
    } catch (error) {
      handleErrorAndSendToBooking();
    }
  };

  const handleErrorAndSendToBooking = () => {
    setShowBanner(
      `We're sorry, this time is no longer available. Please select a different appointment time.`
    );
    history.replace(`first-appointment-book${history.location.search}`);
  };

  useEffect(
    () => {
      // eslint-disable-next-line no-undef
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      if (patientProfile) {
        setIsFetching(true);
        processAppointment();
      } else if (userIdFromWebview) {
        setIsFetching(true);
        processAppointmentNative();
      } else {
        setIsFetching(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [patientProfile]
  );

  const handleBackToMobileAppSegmentEvent = (action) => {
    if (action === MOBILE_ACTION_KEYS.GO_BACK) {
      return trackOnBackToAppointment(provider_role);
    }
    if (
      action === MOBILE_ACTION_KEYS.NEW_REFERRAL_APPT &&
      appointmentType === REFERRAL_APPT_TYPE &&
      provider_role
    ) {
      return trackOnBookAnotherAppointment(provider_role);
    }
  };

  function goBackToMobileApp(action) {
    handleBackToMobileAppSegmentEvent(action);
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ key: 'NAVIGATION', action })
    );
  }

  return (
    <AnimatedRoute
      title='' // Making this a child component for some one-off styling
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <PContainer>
        <Title>Your appointment is booked!</Title>
        <Paragraph>
          We’ve just sent you an email with the appointment details.
        </Paragraph>
      </PContainer>

      {!native && (
        <>
          <DownloadWrapper>
            <DownloadTextContainer>
              <DownloadText>
                Your next step is to<wbr /> download the Oshi app:
              </DownloadText>
            </DownloadTextContainer>
            <OshiDownloadDisplay />
          </DownloadWrapper>
          <VideoPlayer
            src="https://player.vimeo.com/video/736631362?h=726dda25e2&color=FFB59D&title=0&byline=0"
            frameborder="0"
            allow="autoplay; fullscreen;"
            allowfullscreen
          />
        </>
      )}

      {native && appointmentType === REFERRAL_APPT_TYPE && (
        <OshiNextButton
          buttonTitle='Book Another Appointment'
          onClick={() =>
            goBackToMobileApp(MOBILE_ACTION_KEYS.NEW_REFERRAL_APPT)
          }
        >
          <OshiLink
            styles={{
              marginTop: 10,
            }}
            buttonTitle='Back to Appointments'
            onClick={() => goBackToMobileApp(MOBILE_ACTION_KEYS.GO_BACK)}
          ></OshiLink>
        </OshiNextButton>
      )}
      {native && appointmentType !== REFERRAL_APPT_TYPE && (
        <OshiNextButton
          buttonTitle='Back to Appointments'
          onClick={() => goBackToMobileApp(MOBILE_ACTION_KEYS.GO_BACK)}
        />
      )}
    </AnimatedRoute>
  );
};

const DownloadWrapper = styled.div`
  width: 636px;
  @media only screen and (max-width: 636px) {
    width: 100%;
  }
`;

const VideoPlayer = styled.iframe`
  aspect-ratio: 16 / 9;
  padding-bottom: 10vh;
  width: 80vw;
  @media only screen and (max-width: 429px) {
    width: 100vw;
  }
`;

const PContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  margin-top: -60px;
  width: 636px;
  @media only screen and (max-width: 636px) {
    width: 100%;
  }
  @media only screen and (max-width: 429px) {
    margin-top: -30px;
  }
`;

const Title = styled.h1`
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  color: ${palette.navy};
`;

const Paragraph = styled.p`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #66727f;
  padding-top: 16px;
`;

const DownloadTextContainer = styled.div`
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  color: ${palette.navy};
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 16px;

  @media only screen and (min-width: 430px) {
    align-items: center;
    max-width: 100%;
  }
`;
const DownloadText = styled.h1`
  font-size: 56px;
  line-height: 72px;
  @media only screen and (max-width: 429px) {
    font-size: 28px;
    line-height: 37px;
  }
`;

export default connect(null, {
  AppointmentOverviewNote,
  AppointmentOverviewNoteSent,
  AppointmentOverviewNoNoteSent,
  AppointmentOverviewCoachBio,
  setIsFetching,
  TrackMembershipConfirmation,
  TrackAppointmentTaken,
  TrackAppointmentTakenSheduled,
  setShowBanner,
  trackBookAnotherAppointmentEvent,
  trackBackToAppointmentsEvent,
  trackOnLoadedScreenEvent,
})(AppointmentOverview);
