import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react-web';

import { palette } from '../theme/palette';
import OshiHeader from '../components/OshiHeader';
import withTracker from '../wrappers/withTracker';

import animation1 from '../assets/did-you-know-animation.json';
import animation2 from '../assets/book-first-appt-animation.json';

function BookAnAppointment({ setIsFetching }) {
  const history = useHistory();
  const [animation, setAnimation] = useState(animation1);

  function onComplete() {
    setAnimation();

    if (animation === animation1) {
      setAnimation(animation2);
    } else {
      setIsFetching(true);
      history.push('first-appointment-book');
    }
  }

  return (
    <Container>
      <OshiHeader action={() => history.goBack()} />

      <Lottie
        options={{
          animationData: animation,
          loop: false,
        }}
        eventListeners={[{ eventName: 'complete', callback: onComplete }]}
      />
    </Container>
  );
}

const Container = styled.main`
  width: 100vw;
  height: 90vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: relative;
  justify-content: center;
`;

export default withTracker(
  BookAnAppointment,
  'Signup - Get Symptom Relief - Page View'
);
