import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { palette } from '../theme/palette';
import { isMobile } from 'react-device-detect';

function AnimatedTitle({
  animationDirection,
  animationRef,
  resetAnimaions,
  progressPercentage,
  cameAfterBrowserBackButtonClick,
  cameAfterOshiHeaderBackButtonClick,
  title,
}) {
  function getAnime() {
    let anime;

    if (animationDirection === 'mount') {
      anime = {
        from: {
          opacity: 0,
          transform: cameAfterBrowserBackButtonClick
            ? 'translate3d(-100%,0,0)'
            : 'translate3d(100%,0,0)',
        },
        to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
      };
    } else if (animationDirection === 'unmount') {
      anime = {
        from: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        to: {
          opacity: 0,
          transform: cameAfterOshiHeaderBackButtonClick
            ? 'translate3d(100%,0,0)'
            : 'translate3d(-100%,0,0)',
        },
      };
    }

    return anime;
  }

  const anime = getAnime();

  const titleAnime = useSpring({
    reset: resetAnimaions,
    ref: animationRef,
    ...anime,
  });

  function processTitle(title) {
    // web break key is <wbr />
    // mobile break key is <mbr />
    let textArray = [];
    let titleCopy = title;

    if (isMobile) {
      textArray = titleCopy.replace('<wbr />', '').split('<mbr />');
    } else {
      textArray = titleCopy.replace('<mbr />', '').split('<wbr />');
    }

    return textArray;
  }

  return (
    <TitleContainer progressPercentage={progressPercentage} style={titleAnime}>
      {processTitle(title).map((item, key) => (
        <h3 key={key}>{item}</h3>
      ))}
    </TitleContainer>
  );
}

const TitleContainer = styled(animated.div)`
  flex-direction: column;
  display: flex;
  justify-content: left;
  margin: 30px 0 48px;
  font-family: 'Source Serif Pro', serif;
  font-size: 32px;
  color: ${palette.navy};
  line-height: 130%;
  font-weight: 700;
  width: 100%;
  @media only screen and (min-width: 429px) {
    margin-bottom: 60px;
    font-size: 56px;
    margin-top: ${({ progressPercentage }) =>
      progressPercentage >= 0 ? '50px' : '100px'};
  }
`;

export default React.memo(AnimatedTitle);
