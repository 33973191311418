const appointmentStatuses = {
  PENDING: 'Pending', // has 3 different states
  FINISHED: 'Finished', // should not be returned from the BE
  MISSED: 'Missed',
  CANCELED: 'Canceled', // should not be returned from the BE
  RESCHEDULED: 'Rescheduled', // should not be returned from the BE
  MISSED_DISSMISED: 'Dismissed', // new status tell edwin
  INTERNAL_MISSED: 'Internal_Missed',
  INTERNAL_PENDING: 'Internal_Pending',
};

export default appointmentStatuses;
