import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { connect } from 'react-redux';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';

import InputErrorMessage from '../atoms/InputErrorMessage';

import regex from '../utils/regex';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import withTracker from '../wrappers/withTracker';
import { InvalidPassword } from '../actions/segment';
import OshiNextButton from '../components/OshiNextButton';
import { userPasswordSubmitEvent } from '../actions/segment';

function Password({ InvalidPassword, userPasswordSubmitEvent: userPasswordSubmit }) {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [showErrors, setShowErrors] = useState(false);
  function passwordValidate({ password }) {
    const errors = {};

    if (password.length < 8) {
      errors.length = '8 or more characters';
    }

    if (!regex.atLeastOneUpperCase.test(password)) {
      errors.upperCase = 'At least one uppercase letter (A-Z)';
    }

    if (!regex.atLeastOneLetter.test(password)) {
      errors.lowerCase = 'At least one lowercase letter (a-z)';
    }

    if (!regex.atLeastOneDigit.test(password)) {
      errors.digit = 'At least one number (0-9)';
    }

    return errors;
  }

  function handleNext() {
    // errorsArray will only have a single
    // element so we can  use [0]
    // if there are errors we get the message from
    // that error coming from Formik and set it as the error
    const errorsArray = Object.keys(formik.errors);
    if (errorsArray.length > 0) {
      InvalidPassword();
      return setShowErrors(true);
    }

    // store info in redux;
    // animate and transition
    userPasswordSubmit();
    return setAnimationDirection('unmount');
  }

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validate: passwordValidate,
    onSubmit: () => handleNext(),
  });

  function clearParentErrorOnFocus() {
    setShowErrors(false);
  }

  const EnterKeyPress = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
    }
    if (
      evt.keyCode === 13 &&
      formik.dirty &&
      Object.keys(formik.errors).length === 0
    ) {
      handleNext();
    } else if (evt.keyCode === 13 && formik.values.password === '') {
      const errorsArray = Object.keys(formik.errors);
      setShowErrors(formik.errors[errorsArray[0]]);
      setShowErrors(true);
    }
    if (formik.dirty) {
      setShowErrors(false);
    }
    const errorsArray = Object.keys(formik.errors);
    if (evt.keyCode === 13 && errorsArray.length > 0) {
      if (errorsArray.length > 0) {
        InvalidPassword();
        return setShowErrors(true);
      }
    }
  };

  return (
    <AnimatedRoute
      nextRoute='/privacy-policy'
      nextRouteProps={{ password: formik.values.password }}
      title='Choose a<wbr /> password'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
      progressPercentage={83}
    >
      <Form onKeyDown={EnterKeyPress}>
        <PContainer>
          <Paragraph>
            You will use this password to sign into the Oshi App to connect with
            your Care Team.
          </Paragraph>
        </PContainer>

        <OshiInput
          id='password'
          type='password'
          label='Password'
          autoFill
          error={showErrors}
          autoFocus
          showCheckMark
          clearParentErrorOnFocus={clearParentErrorOnFocus}
          isValid={formik.dirty && Object.keys(formik.errors).length === 0}
          segmentLabel='Sign Up - Password'
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        {!showErrors && (
          <ErrorsContainer>
            <InputErrorMessage
              showCheckMark
              formik={formik}
              value={formik.values.password}
              isValid={formik.dirty && !formik.errors.length}
              error='Minimum of 8 characters'
            />
            <InputErrorMessage
              showCheckMark
              formik={formik}
              value={formik.values.password}
              isValid={formik.dirty && !formik.errors.upperCase}
              error='At least one uppercase letter (A-Z)'
            />
            <InputErrorMessage
              showCheckMark
              formik={formik}
              value={formik.values.password}
              isValid={formik.dirty && !formik.errors.lowerCase}
              error='At least one lowercase letter (a-z)'
            />
            <InputErrorMessage
              showCheckMark
              formik={formik}
              value={formik.values.password}
              isValid={formik.dirty && !formik.errors.digit}
              error='At least one number (0-9)'
            />
          </ErrorsContainer>
        )}

        {showErrors && (
          <ErrorContainer>
            <Typography styles={{ color: palette.error }}>
              Create a secure password to protect your health information
            </Typography>
          </ErrorContainer>
        )}
      </Form>

      <OshiNextButton
        buttonTitle='Continue'
        disabled={!formik.dirty}
        onClick={handleNext}
      />
    </AnimatedRoute>
  );
}

const Form = styled.form`
  width: 100%;
`;

const PContainer = styled.div`
  display: flex;
  max-width: 100%;
  margin-bottom: 48px;
  margin-top: -30px;

  @media only screen and (min-width: 429px) {
    margin-bottom: 42px;
  }
`;

const Paragraph = styled.p`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #66727f;
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

export default connect(null, {
  InvalidPassword,
  userPasswordSubmitEvent,
})(withTracker(Password, 'Signup - Password - Page View'));
