/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:9287fd83-0c5e-4b9b-b26f-5c842bd830e2',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_nsWyHYY6w',
  aws_user_pools_web_client_id: '32prnel0mebsbblmkeb67s949o',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_cloud_logic_custom: [
    {
      name: 'oshiAPI',
      endpoint:
        'https://bki9hrr6aj.execute-api.us-east-1.amazonaws.com/devfour',
      region: 'us-east-1',
    },
    {
      name: 'daemon',
      endpoint: 'https://8rxiplfr4c.execute-api.us-east-1.amazonaws.com',
      region: 'us-east-1',
    },
  ],
  aws_appsync_graphqlEndpoint:
    'https://odiikrknpvfv3apjqm2k2cejau.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-y4gxmqxqp5esfapy7xlswijmxu',
  aws_mobile_analytics_app_id: 'd5124fadca354a43be315fbfd201533f',
  aws_mobile_analytics_app_region: 'us-east-1',
  aws_user_files_s3_bucket: 'multimedia122817-devfour',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_dynamodb_all_tables_region: 'us-east-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'cacheddata-devfour',
      region: 'us-east-1',
    },
    {
      tableName: 'authsessions-devfour',
      region: 'us-east-1',
    },
    {
      tableName: 'multimediameta-devfour',
      region: 'us-east-1',
    },
    {
      tableName: 'extradata-devfour',
      region: 'us-east-1',
    },
  ],
};
export default awsmobile;
