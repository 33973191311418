import { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { localStorageGet } from '../utils/localStorageHelper';
import useYupSchemaBuilder from './useYupSchemaBuilder';

/**
 * Custom hook to handle initial configuration and UI events of address page UI form.
 * Handles how to format properly location address by using google maps API, builts form validation
 * configuration by using methods from yup schema builder hook and handles form submission UI events.
 *
 * @param { Object } uiEvents - Set of UI events handlers for UI form submission
 * @return { Object } - { formik, handleSubmit, handleOnKeyPress } - `formik` property returns formik
 * configuration to be used in the UI logic of the UI page component. `handleSubmit` and `handleOnKeyPress`
 * are functions to handle UI form submission but with slight difference, the latter will submit the form
 * by hitting `enter` key, the former with a single `click`.
 * */
const useAddressUIForm = ({ handleSubmitFn, handleOnKeyPressFn }) => {
  const {
    getYupShape,
    zipCodeValidator,
    textValidator,
  } = useYupSchemaBuilder();
  const history = useHistory();
  const { location } = history;
  const { zipcode } = localStorageGet('zipcode');
  const addressFormSchema = getYupShape({
    streetAddress: textValidator({ required: true }),
    zip: zipCodeValidator(),
    city: textValidator({ required: true, noWhitespace: true }),
    state: textValidator({ required: true, noWhitespace: true }),
  });
  const getFormatedAddress = useCallback(() => {
    let plusCode;
    const result = {
      streetNumber: '',
      streetName: '',
      streetAddress: '',
      apartment: '',
      city: '',
      state: '',
      zip: '',
    };
    // If props from the google search were passed to this route
    // then enter block of code

    // if coming from off boarding get the address from the local storage
    if (history.action === 'POP' && location && location.state) {
      return localStorageGet('address');
    }
    if (location && location.state) {
      const suggestedAddressesArray =
        location.state.googleMapsSuggestedAddress.addressComponent;
      // the plus code comes from google maps api when
      // there is no street number for that address
      plusCode = location.state.googleMapsSuggestedAddress.plus_code
        ? location.state.googleMapsSuggestedAddress.plus_code.compound_code.split(
            ' '
          )[0]
        : false;
      const mappableKeys = {
        streetNumber: ['street_number'],
        streetName: ['route'],
        zip: ['postal_code'],
        city: ['locality', 'neighborhood', 'sublocality_level_1'],
        state: ['administrative_area_level_1'],
      };

      suggestedAddressesArray.forEach((item) => {
        if (item.types.length > 1) {
          item.types.forEach((type) => {
            Object.keys(mappableKeys).forEach((key) => {
              const mappableKey = mappableKeys[key];
              if (mappableKey.includes(type)) {
                result[key] = item.long_name;
              }
            });
          });
        } else if (item.types[0]) {
          Object.keys(mappableKeys).forEach((key) => {
            const mappableKey = mappableKeys[key];
            if (mappableKey.includes(item.types[0])) {
              result[key] = item.long_name;
            }
          });
        }
      });
    }

    result.streetAddress = plusCode
      ? `${plusCode} ${result.streetNumber} ${result.streetName}`
      : `${result.streetNumber} ${result.streetName}`;

    return result;
  }, [location, history]);

  const [googleMapsSuggestedAddress] = useState(getFormatedAddress());
  const {
    streetAddress,
    apartment,
    city,
    state,
    zip,
  } = googleMapsSuggestedAddress;

  const handleSubmit = (values) => {
    handleSubmitFn(values);
  };

  const formik = useFormik({
    initialStatus: { existingValues: zipcode },
    initialValues: {
      streetAddress,
      apartment,
      city,
      state,
      zip,
    },
    validationSchema: addressFormSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleOnKeyPress = (evt) => {
    handleOnKeyPressFn(evt, formik);
  };

  return { formik, handleSubmit, handleOnKeyPress };
};

export default useAddressUIForm;
